<template>
    <div>
        <div class="tw-flex tw-justify-between tw-items-center tw-p-4">
            <div class="tw-flex tw-items-center tw-space-x-3">
                <a-range-picker
                    v-model="dateRange"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                    valueFormat="DD-MM-YYYY"
                    style="width: 200px"
                    @change="fetchData()"
                />
                <a-radio-group
                    v-model="is_driver_clear_cash"
                    @change="fetchData()"
                >
                    <a-radio-button :value="null">
                        {{ $t("driverClearCash.all") }}
                    </a-radio-button>
                    <a-radio-button :value="1">
                        {{ $t("driverClearCash.cleared") }}
                    </a-radio-button>
                    <a-radio-button :value="0">
                        {{ $t("driverClearCash.noClear") }}
                    </a-radio-button>
                </a-radio-group>
            </div>
            <div class="tw-space-x-3">
                <a-tooltip :title="$t('driverClearCash.search')">
                    <a-input-search
                        v-model="search"
                        :placeholder="$t('driverClearCash.search')"
                        style="width: 200px"
                    />
                </a-tooltip>
            </div>
        </div>
        <div class="tw-overflow-x-scroll">
            <ts-table
                :columns="columns"
                :records="resourcesAll"
                :loading="loading"
                :expandable="true"
            >
                <template v-slot="{ record }">
                    <td>{{ record.transaction_date }}</td>
                    <td>{{ record.driver_clear_cash_date }}</td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        {{ record.driver_code }}
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        {{ record.driver_name }}
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        <ts-accounting-format
                            :digit="2"
                            :value="record.amount_tobe_collected"
                        />
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        <ts-accounting-format
                            :digit="2"
                            :value="record.amount_collected_usd"
                        />
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="record.amount_tobe_collected_khr"
                        />
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="record.amount_collected_khr"
                        />
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="record.taxi_fee"
                        />
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        <ts-accounting-format
                            :digit="2"
                            :value="record.amount_tobe_cleared_usd"
                        />
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="record.amount_tobe_cleared_khr"
                        />
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                    >
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="record.amount_tobe_collected_khr - record.amount_collected_khr"
                        />
                    </td>
                    <td class="tw-text-center">
                        <a-badge
                            v-if="record.is_driver_clear_cash"
                            count="Yes"
                            :number-style="{
                                backgroundColor: '#52c41a'
                            }"
                        />
                        <a-badge v-else count="No" />
                    </td>
                    <td class="tw-whitespace-nowrap tw-text-center">
                        <a-popconfirm
                            placement="leftTop"
                            :title="$t('are_you_sure_to_proceed')"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                            @confirm="previewPdf(record)"
                            v-if="!record.is_driver_clear_cash"
                        >
                            <a href="#">
                                <a-tooltip placement="left">
                                    <template v-slot:title>{{
                                        $t("clearance")
                                    }}</template>
                                    <i class="fas fa-hand-holding-usd"></i>
                                </a-tooltip>
                            </a>
                        </a-popconfirm>
                        <a href="#" 
													v-else
													@click.prevent="previewPdf(record)"
												>
                            <a-tooltip placement="left">
                                <template v-slot:title>{{
                                    $t("pdf")
                                }}</template>
                                <i class="fas fa-file-pdf tw-text-red-500"></i>
                            </a-tooltip>
                        </a>
                    </td>
                </template>
                <template v-slot:expand="{ record }">
                    <Details :record="record.details" />
                </template>
                <template v-slot:tfooter>
                    <tr class="tw-text-blue-500 tw-text-white tw-font-semibold">
                        <td colspan="6" class="tw-text-right">
                            <b style="border:none">{{ $t("total") }} :</b>
                        </td>
                        <td class="tw-border">
                            <ts-accounting-format
                                style="border:none"
                                :digit="2"
                                :value="sumTotal('amount_tobe_collected')"
                            />
                        </td>
                        <td class="tw-border">
                            <ts-accounting-format
                                style="border:none"
                                :digit="2"
                                :value="sumTotal('amount_collected_usd')"
                            />
                        </td>
                        <td class="tw-border">
                            <ts-accounting-format
                                style="border:none"
                                currency="KHR"
                                :digit="0"
                                :value="sumTotal('amount_tobe_collected_khr')"
                            />
                        </td>
                        <td class="tw-border">
                            <ts-accounting-format
                                style="border:none"
                                currency="KHR"
                                :digit="0"
                                :value="sumTotal('amount_collected_khr')"
                            />
                        </td>
                        <td class="tw-border">
                            <ts-accounting-format
                                style="border:none"
                                currency="KHR"
                                :digit="0"
                                :value="sumTotal('taxi_fee')"
                            />
                        </td>
                        <td class="tw-border">
                            <ts-accounting-format
                                style="border:none"
                                :digit="2"
                                :value="sumTotal('amount_tobe_cleared_usd')"
                            />
                        </td>
                        <td class="tw-border">
                            <ts-accounting-format
                                style="border:none"
                                currency="KHR"
                                :digit="0"
                                :value="sumTotal('amount_tobe_cleared_khr')"
                            />
                        </td>
                        <td class="tw-border">
                            <ts-accounting-format
                                style="border:none"
                                currency="KHR"
                                :digit="0"
                                :value="sumMargin"
                            />
                        </td>
                        <td colspan="2"></td>
                    </tr>
                </template>
            </ts-table>
        </div>
        <div class="d-flex justify-content-end tw-p-4">
            <ts-pagination
                v-model="paginateAll"
                @navigate="fetchData"
            ></ts-pagination>
        </div>
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="waiting" :src="src" />
        </a-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce, sumBy } from "lodash";
import Details from "./detail.vue";

export default {
    name: "tableDetailAllData",
    components: {
        Details
    },
    data() {
        return {
            loading: false,
            preview_pdf: false,
            waiting: false,
            src: ""
        };
    },
    computed: {
        ...mapState("finance/driverClearCash", ["resourcesAll", "paginateAll"]),
        search: {
            get() {
                return this.$store.state.finance.driverClearCash.search;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/driverClearCash/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "finance/driverClearCash/RESET_CURRENT_PAGE"
                );
            }
        },
        is_driver_clear_cash: {
            get() {
                return this.$store.state.finance.driverClearCash
                    .is_driver_clear_cash;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/driverClearCash/SET_IS_DRIVER_CLEAR_CASH",
                    newValue
                );
            }
        },
        columns() {
            return [
                { name: this.$t("driverClearCash.transactionDate") },
                { name: this.$t("driverClearCash.clearDate") },
                { name: this.$t("driverClearCash.driverCode") },
                { name: this.$t("driverClearCash.driverName") },
                { name: this.$t("driverClearCash.toBeCollectedUSD") },
                { name: this.$t("driverClearCash.collectedUSD") },
                { name: this.$t("driverClearCash.toBeCollectedKHR") },
                { name: this.$t("driverClearCash.collectedKHR") },
                { name: this.$t("driverClearCash.taxiFee") },
                { name: this.$t("driverClearCash.toBeClearedUSD") },
                { name: this.$t("driverClearCash.toBeClearedKHR") },
                { name: this.$t("driverClearCash.margin") },
                { name: this.$t("driverClearCash.isClearCash") },
                { name: this.$t("actions") }
            ];
        },
        dateRange: {
            get() {
                return this.$store.state.finance.driverClearCash.dateRangeAll;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/driverClearCash/SET_DATE_RANGE_ALL",
                    newValue
                );
            }
        },
        sumTotal: vm => column => {
            var amount = sumBy(vm.resourcesAll, column);

            return amount;
        },
				sumMargin: vm => {
					return sumBy(vm.resourcesAll, o => o.amount_tobe_collected_khr - o.amount_collected_khr);
				}
    },
    methods: {
        ...mapActions("finance/driverClearCash", ["store", "toPdf"]),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("finance/driverClearCash/fetchAllData", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        previewPdf(record) {
            if (!record.is_driver_clear_cash) {
                this.clearance(record);
            } else {
                this.onlyPdf(record);
            }
        },
        clearance(record) {
            this.preview_pdf = true;
            this.waiting = true;
            this.store({
                transaction_date: record.transaction_date,
                driver_id: record.driver_id,
                remark: ""
            })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.src = response.url;
                    this.fetchData();
                })
                .catch(error => {
                    this.preview_pdf = false;
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onlyPdf(record) {
            this.preview_pdf = true;
            this.waiting = true;
            this.toPdf(record.driver_clear_cash_id)
                .then(response => {
                    this.src = response.url;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    }
};
</script>
